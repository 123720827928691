<template>
    <div class="p-2" v-if="vet">

        <!-- header -->
        <div class="p-1" style="border-radius: 10px;border:1px solid #FFAD32">
            <img style="border-radius: 10px;max-height:150px;object-fit:contain" class="w-100" :src="vet.vet_picture" />

            <div class="text-white text-center my-1 px-2">
                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                    <h2 class="text-white font-weight-bolder mb-0">{{ vet.vet_name }}</h2>
                    <router-link :to="`/chats/${vet.user_id}`" v-if="user.user_id != vet.user_id">
                        <div class="text-white d-flex align-items-center"
                            style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                    <router-link :to="`/vet-register`" v-else>
                        <div class="text-white d-flex align-items-center"
                            style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-edit" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                </div>
                <p class="mt-1">
                    {{ vet.vet_details }}
                </p>

                <h3 class="font-weight-bold text-white">{{ vet.vet_location }}</h3>
                <h3 class="font-weight-bold text-white mb-0">{{ vet.vet_phone }}</h3>
            </div>

            <div class="d-flex">
                <div class="badge bg-danger m-auto" v-if="vet.is_approved == 'n'">
                    Waiting for approval
                </div>
            </div>
        </div>
        <!-- end header -->

        <!-- services -->
        <div class="my-3" v-if="user.user_id == vet.user_id">
            <h3 class="font-weight-bold text-white">Manage Schedule</h3>

            <div class="mt-1 mb-2">

                <div class="d-flex align-items-center mb-1" style="gap:10px">
                    <input class="flex-fill form-control" v-model="trs_date" type="date"
                        style="background: transparent;color: white;border:2px solid #E3A230;" />
                    <input class="flex-fill form-control" v-model="trs_time" type="time"
                        style="background: transparent;color: white;border:2px solid #E3A230;" />
                </div>

                <div class="mt-1 d-flex">
                    <div class="text-white btn mx-auto" @click="onSubmit()"
                        style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                        <div class="h4 text-white mb-0">Add Schedule</div>
                    </div>
                </div>

            </div>

            <div v-for="(item, key) in schedules">
                <h4 class="text-white mb-1 mt-2">Schedule {{ moment(key).format('dddd, DD MMMM YYYY') }}</h4>
                <div v-for="time in item">
                    <router-link :to="`/vet/book-list/${time.vs_id}`" class="text-white d-flex align-items-center p-1 mt-1"
                        style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                        <div class="h4 text-white mb-0">
                            {{ time.vs_time }}
                            <div class="badge badge-danger" v-if="time.pending.length > 0">{{ time.pending.length }}</div>
                            <div class="badge badge-success" v-if="time.approved.length > 0">{{ time.approved.length }}</div>
                        </div>
                        <div class="h4 text-white mb-0 ml-auto" @click="deleteSchedule(time.vs_id)" v-if="user.user_id == vet.user_id">
                            <i class="bx bx-trash"></i></div> 
                    </router-link>
                </div>
            </div>
        </div>
        <div class="my-3" v-else>
            <h3 class="font-weight-bold text-white">Book Schedule</h3>
            <div class="d-flex align-items-center mb-1" style="gap:10px">
                <select class="form-control" v-model="selected_date" placeholder="Select Date" style="background: transparent;border:2px solid #E3A230;" >
                    <option selected :value="null">
                        Select Date
                    </option>
                    <template v-for="(item, key) in schedules">
                        <option :value="item">{{ key }}</option>
                    </template>
                </select>
                <select class="form-control" v-model="selected_time" style="background: transparent;border:2px solid #E3A230;" v-if="selected_date">
                    <option selected :value="null">
                        Select Time
                    </option>
                    <template v-for="(time, key) in selected_date">
                        <option :value="time.vs_id" :disabled="time.approved.length > 0">{{ time.vs_time }}</option>
                    </template>
                </select>
                <select class="form-control" disabled style="background: transparent;color: white;border:2px solid #E3A230;" v-else>
                    <option selected value="">
                        Select Time
                    </option>
                </select>
            </div> 

            <div class="mt-1 d-flex">
                <div class="text-white btn mx-auto" @click="bookSchedule()"
                    style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                    <div class="h4 text-white mb-0">Book Schedule</div>
                </div>
            </div>
        </div>
        <!-- end services -->

    </div>
</template>

<script>

import store from '@/store'
import moment from 'moment'
import Swal from 'sweetalert2'

export default {
    computed: {
        user() {
            return store.state.auth.USER
        },
        vet() {
            return store.state.vet.VET
        },
        schedules(){
            return this.vet?.schedules_ || []
        },
    },
    methods: {
        onSubmit(){
            if(this.trs_date && this.trs_time){
                let formData = new FormData()
                formData.append('inp[vs_date]', this.trs_date)
                formData.append('inp[vs_time]', this.trs_time)
                formData.append('inp[vet_id]', this.id)
                store.dispatch('vet/SaveSchedule', formData).then(() => {
                    store.dispatch('vet/GetVet', this.id)
                    this.trs_date = ''
                    this.trs_time = ''
                })
            } else {
                Swal.fire('Warning', 'Please input all fields to continue.')
            }
        },
        deleteSchedule(id){
            store.dispatch('vet/DeleteSchedule', id).then(() => {
                store.dispatch('vet/GetVet', this.id)
            })
        },
        bookSchedule(){
            if(this.selected_time){
                let formData = new FormData()
                formData.append('vs_id', this.selected_time)
                formData.append('user_id', this.user.user_id)
                store.dispatch('vet/BookSchedule', formData).then((res) => {
                    this.$router.push('/vet/profile/'+this.id+'/'+this.user.user_id+'/status/'+res.data.status+'/'+res.data.note)
                })
            } else {
                Swal.fire('Warning', 'Please select a schedule and time.')
            }
        }
    },
    mounted() {
        store.dispatch('vet/GetVet', this.id)
    },
    data() {
        return {
            id: this.$route.params.id,
            trs_date: '',
            trs_time: '',
            moment,
            selected_date: null,
            selected_time: null
        }
    }
}

</script>

<style>
    select.form-control, select.form-control:active {
        color:white !important
    }
    select.form-control option, select.form-control:active option {
        color:white !important;
        background-color: #1B628C !important;
    }
</style>